<template>
  <div class="scene" data-id="error">
    <h1 class="title-text__title">404</h1>
    <p class="title-text__text" v-html="$translate('404').message" />

    <BaseCta to="/" :content="$translate('404').cta" />
  </div>
</template>

<script>
import BaseCta from '@/components/BaseCta';

export default {
  name: 'Error',

  components: {
    BaseCta,
  },
};
</script>

<style lang="scss" scoped>
.scene {
  h1 {
    margin: 0 0 grid-size(1.5);

    @include font-size($fluid-l...);

    @include mq(ipadP) {
      margin: grid-size(-0.75) 0 grid-size(0.5);
    }
  }

  a {
    position: relative;

    @include bottom-line();
  }
}
</style>
